import React, { FunctionComponent } from 'react'
import { WindowLocation } from '@reach/router'
import { graphql } from 'gatsby'
import DefaultLayout from './index'

type DocumentationPageProps = {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        date: string
        version: string
      }
    }
  }
  location: WindowLocation
}

const Documentation: FunctionComponent<DocumentationPageProps> = ({
  data,
  location,
}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <DefaultLayout location={location}>
      <div className="documentation-body-container">
        <div className="documentation-body">
          <p>
            <b>
              {frontmatter.date} - {frontmatter.version}
            </b>
          </p>
          <div
            className="documentation-body-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        version
      }
    }
  }
`

export default Documentation
