import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import Link from 'gatsby-link'
import { Helmet } from 'react-helmet'

import Typography from '@material-ui/core/Typography'
import amber from '@material-ui/core/colors/amber'
import orange from '@material-ui/core/colors/orange'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'

import './index.css'
import DefaultFooter from '../components/default-footer'

type HeaderProps = {
  suppressSignOutLink?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({ suppressSignOutLink }) => (
  <div
    style={{
      marginBottom: '1.45rem',
    }}>
    <div
      style={{
        margin: '0 auto',
        padding: '10px',
        maxWidth: 960,
      }}>
      <Link
        to="/admin"
        style={{
          color: 'black',
          display: 'inline',
          verticalAlign: 'middle',
          textDecoration: 'none',
        }}>
        <img
          style={{ display: 'inline', height: '40px', margin: 0 }}
          src="/assets/images/Icon.png"
        />
        <h1 style={{ display: 'inline' }}> HostBurro </h1>
      </Link>

      {!suppressSignOutLink ? (
        <div
          style={{
            float: 'right',
          }}>
          <Link
            to="/sign-out"
            style={{
              color: 'black',
              display: 'inline',
              verticalAlign: 'middle',
              textDecoration: 'none',
            }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
              Sign out
            </Typography>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
    <hr
      style={{
        color: orange[100],
        backgroundColor: orange[100],
      }}
    />
  </div>
)

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  firebase?: firebase.FirebaseApp
  suppressSignOutLink?: boolean
  location: {
    pathname: string
  }
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: orange[500],
      },
      secondary: {
        main: amber['A700'],
      },
    },
  })

  return (
    <div>
      <Helmet
        title="HostBurro"
        meta={[
          { name: 'description', content: 'No-hassle Site Hosting' },
          { name: 'keywords', content: 'hosting, website, static site' },
        ]}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link rel="preconnect" href="https://firebase.googleapis.com" />
        <link rel="preconnect" href="https://apis.google.com" />
        <link rel="preconnect" href="https://www.googleapis.com" />
        <link rel="preconnect" href="https://identitytoolkit.googleapis.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header suppressSignOutLink={props.suppressSignOutLink} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}>
          {props.children}
        </div>
      </ThemeProvider>
      <DefaultFooter firebase={props.firebase} />
    </div>
  )
}

export default DefaultLayout
